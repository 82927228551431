import { useState, useEffect } from "react";
import InAppSpy from "inapp-spy";
import Bowser from "bowser";

export default function RootLayout() {
  const [isInApp, setIsInApp] = useState(false);
  const [phoneType, setPhoneType] = useState(undefined);
  const [hasRedirected, setHasRedirected] = useState(false);
  const [showRedirectButton, setShowRedirectButton] = useState(false);

  useEffect(() => {
    const { isInApp: isInAppFromSpy, ua } = InAppSpy();
    setIsInApp(isInAppFromSpy);

    if (isInAppFromSpy) {
      const parser = Bowser.getParser(ua);
      const os = parser.getOSName();
      if (os === "Android") setPhoneType("android");
      else if (os === "iOS") setPhoneType("ios");
    }

    const url = new URL(window.location.href);
    if (url.searchParams.get("redirected") === "true") {
      setHasRedirected(true);
      url.searchParams.delete("redirected");
      window.history.replaceState({}, "", url.toString());
    }
  }, []);

  useEffect(() => {
    if (isInApp && phoneType && !hasRedirected) {
      const timer = setTimeout(() => {
        if (phoneType === "android") {
          // Android URL — без 'https://'
          const url = "google.com"; // Указываем только домен и путь
          window.location.href = `intent:${url}?redirected=true#Intent;scheme=https;package=com.android.chrome;end`;
        } else if (phoneType === "ios") {
          // iOS URL — с 'https://'
          const baseUrl = "https://google.com"; // Полная ссылка с https://
          window.location.href = `x-safari-${baseUrl}?redirected=true`;
          setTimeout(() => setShowRedirectButton(true), 3000); // Показываем кнопку, если Safari не открылся
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isInApp, phoneType, hasRedirected]);

  const handleManualRedirect = () => {
    const url = "google.com"; // Полная ссылка с https:// для ручного перехода
    window.open(url, "_blank");
  };

  return (
    <div style={{ textAlign: "center", fontSize: "20px", marginTop: "20%" }}>
      {showRedirectButton ? (
        <button onClick={handleManualRedirect} style={{ fontSize: "24px", padding: "10px 20px" }}>
          Открыть в Safari
        </button>
      ) : (
        <div>3 , 2 , 1 ...</div>
      )}
    </div>
  );
}
